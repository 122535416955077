import React, { useState, useEffect } from 'react';

const Birthday = ({ epochMS, children }) => {
  // useState Hooks
  const [state, setState] = useState({
    seconds: 0,
    hours: 0,
    minutes: 0,
    days: 0,
    reveal: false,
  });

  const [intervalID, setIntervalID] = useState();

  useEffect(() => {
    const countdown = () => {
      const dateAtm = new Date();
      let birthdayDay = new Date(epochMS);

      if (dateAtm >= birthdayDay) {
        setState(prev => ({ ...prev, reveal: true }));
        if (intervalID) clearInterval(intervalID);
        return;
      };

      const currentTime = dateAtm.getTime();
      const birthdayTime = birthdayDay.getTime();
      const timeRemaining = birthdayTime - currentTime;

      let seconds = Math.floor(timeRemaining / 1000);
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
      let days = Math.floor(hours / 24);

      seconds %= 60;
      minutes %= 60;
      hours %= 24;

      if (dateAtm >= birthdayDay) {
        setState(prev => ({ ...prev, reveal: true }));
      } else {
        setState(prev => ({ ...prev, seconds, minutes, hours, days, }));
      }
    };

    countdown();
    setIntervalID(setInterval(countdown, 1000));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !state.reveal ? (
    <div className='countdown-wrapper-col'>
      <div className='countdown-wrapper-row'>
        <div className='countdown-box'>
          {state.days}
          <span className='legend'>Days</span>
        </div>
        <div className='countdown-box'>
          {state.hours}
          <span className='legend'>Hours</span>
        </div>
      </div>
      <div className='countdown-wrapper-row'>
        <div className='countdown-box'>
          {state.minutes}
          <span className='legend'>Minutes</span>
        </div>
        <div className='countdown-box'>
          {state.seconds}
          <span className='legend'>Seconds</span>
        </div>
      </div>
    </div>
  ) : children;
};

export default Birthday;
