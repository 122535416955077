import React, { useMemo } from 'react';
import { Confetti } from 'react-confetti-cannon';
import Countdown from './components/Countdown';
import Lock from './components/Lock';
import Wishes from './components/Wishes';

const App = () => {
  const launchPoints = useMemo(
    () => [
      () => ({
        x: 0,
        y: 2 * window.innerHeight / 3,
        angle: -20 * 2 * Math.PI / 360,
      }),
      () => ({
        x: window.innerWidth,
        y: 2 * window.innerHeight / 3,
        angle: 20 * 2 * Math.PI / 360,
      }),
    ],
    []
  );

  return (
    <div className="page">
      <Countdown epochMS={1652911200000}>
        <Confetti
          launchPoints={launchPoints}
          burstAmount={30}
          afterBurstAmount={20}
          gravity={{ x: 0, y: 0.3 }}
        />
        <Lock>
          <Wishes />
          <Confetti
            launchPoints={launchPoints}
            burstAmount={50}
            afterBurstAmount={40}
            gravity={{ x: 0, y: 0.3 }}
            />
        </Lock>
      </ Countdown>
    </div>
  );
}

export default App;