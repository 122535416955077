import React, { useState, useCallback } from 'react';
import sha256 from 'crypto-js/sha256';
import { HiArrowRight } from 'react-icons/hi';
import { FaQuestion } from 'react-icons/fa';

/**
 * NOTE:
 * This is not meant to be secure, and it isn't. Client-side code can be modified, so this entire
 * lock can be bypassed trivially. This is just a fun project with a silly "password lock".
 */

// in terms of security, this is literally nothing as it can be modified
const hash = raw => sha256(raw).toString();
const validate = raw => hash(raw) === '80850733fc1dd4c785789824425fd70cc60e1fc3c871a62e1a2fe4cdefd321b7';

const Lock = ({ children }) => {
  const [value, setValue] = useState('');
  const [locked, setLocked] = useState(true);

  const onChange = useCallback((event) => {
    setValue(event.target.value);
  }, [setValue]);

  const onSubmit = useCallback((event) => {
    event.preventDefault();
    if (validate(value)) setLocked(false);
  }, [value]);

  return locked ? (
    <div>
      <FaQuestion className='question' size={70} />
      <form className='form' onSubmit={onSubmit}>
        <input type="text" value={value} onChange={onChange} />
        <button type="submit"><HiArrowRight size={30} /></button>
      </form>
    </div>
  ) : children;
};

export default Lock;