import React from 'react';

import Background from './Background';
import hbd from '../assets/hbd.png';
import portrait from '../assets/portrait.jpg';

const Wishes = () => {
  return (
    <div className='wishes-body'>
      <Background />
      <div className='card'>
        <img src={portrait} width='100%' alt="Two lovebirds" />
        <img src={hbd} width='100%' alt="Happy Birthday, Maheen" />
      </div>
    </div>
  );
};

export default Wishes;