import React from 'react';
import * as PIXI from 'pixi.js';
import { Sprite, Stage, ParticleContainer, withPixiApp } from '@inlet/react-pixi';

import heart from '../assets/heart.png';
import ketchup from '../assets/ketchup.png';

const random = (min, max) => Math.random() * (max - min) + min;

const config = {
  count: 50,

  properties: {
    position: true,
    rotation: true,
    scale: false,
    uvs: false,
    alpha: false,
  },

  listeners: [],
  onChange: function (prop, val) { this.listeners.forEach(l => l(prop, val)) },
}

const Heart = props => (
  <Sprite {...props}
    image={heart}
    anchor={0.5}
    overwriteProps={true}
    ignoreEvents={true} />
);

const Ketchup = props => (
  <Sprite {...props}
    image={ketchup}
    anchor={0.5}
    overwriteProps={true}
    ignoreEvents={true} />
);

class Settings extends React.PureComponent {

  state = { ...config.properties, count: config.count, changed: false }

  componentDidMount() {
    config.listeners.push(this.onChange)
  }

  onChange = (prop, val) => {
    this.setState({ [prop]: val, changed: true })

    // creates new ParticleContainer as properties cannot be changed over time
    clearTimeout(this.changeTimeout)
    this.changeTimeout = setTimeout(() => this.setState({ changed: false }), 0)
  }

  render() {
    return this.state.changed ? null : this.props.children(this.state)
  }
}

const Batch = withPixiApp(class extends React.PureComponent {
  time = 0
  bounds = null
  state = { items: [], count: 0, component: null, tint: false, maxScale: 0 }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.count === nextProps.count && prevState.component === nextProps.component) {
      return prevState;
    }

    return {
      count: nextProps.count,
      component: nextProps.component,
      items: [...Array(nextProps.count)].map(() => {
        const scale = random(0.05, nextProps.maxScale);

        return {
          speed: random(2, 1 / (scale * 100)),
          offset: Math.random() * 100,
          turningSpeed: random(-0.4, 0.4),
          direction: random(0, 2 * Math.PI),
          tint: nextProps.tint ? Math.random() * 0xFFFFFF : undefined,
          alpha: scale * 10,
          x: random(0, window.innerWidth),
          y: random(0, window.innerHeight),
          _s: scale,
          rotation: 0,
        };
      })
    }
  }

  componentDidMount() {
    const padding = 100

    this.bounds = new PIXI.Rectangle(
      -padding,
      -padding,
      this.props.app.screen.width + padding * 2,
      this.props.app.screen.height + padding * 2
    )

    this.props.app.ticker.add(this.tick)
  }

  componentWillUnmount() {
    this.props.app.ticker.remove(this.tick)
  }

  tick = () => {
    this.setState(
      ({ items }) => ({
        items: items.map(item => {
          let newItem = {
            scale: item._s + Math.sin(this.time * item._s) * 0.25,
            x: item.x + Math.sin(item.direction) * (item.speed * item._s),
            y: item.y + Math.cos(item.direction) * (item.speed * item._s),
            rotation: -item.direction + Math.PI,
            direction: item.direction + item.turningSpeed * 0.01,
          }

          if (newItem.x < this.bounds.x) {
            newItem.x += this.bounds.width
          } else if (newItem.x > this.bounds.x + this.bounds.width) {
            newItem.x -= this.bounds.width
          }

          if (newItem.y < this.bounds.y) {
            newItem.y += this.bounds.height
          } else if (newItem.y > this.bounds.y + this.bounds.height) {
            newItem.y -= this.bounds.height
          }

          return { ...item, ...newItem }
        })
      })
    )

    this.time += 0.1
  }

  render() {
    const Comp = this.props.component
    return this.state.items.map(props => <Comp {...props} />)
  }
})

const Background = () => (
  <div className='background'>
    <Stage width={window.innerWidth} height={window.innerHeight} options={{ backgroundAlpha: 0 }}>
      <Settings>
        {config => (
          <ParticleContainer properties={config}>
            <Batch count={config.count} component={Heart} tint={true} maxScale={0.3} />
          </ParticleContainer>
        )}
      </Settings>

      <Settings>
        {config => (
          <ParticleContainer properties={config}>
            <Batch count={config.count} component={Ketchup} tint={false} maxScale={0.1} />
          </ParticleContainer>
        )}
      </Settings>
    </Stage>
  </div>
);

export default Background;